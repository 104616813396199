import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [prompt, setPrompt] = useState('');
  const [chatMessage, setChatMessage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  // Function to handle prompt change
  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  // Function to handle chat message change
  const handleChatMessageChange = (event) => {
    setChatMessage(event.target.value);
  };

  // Function to send data to the worker
  const sendToWorker = async (data) => {
    // Log the data being sent to the worker
    console.log("Sending data to worker:", JSON.stringify(data, null, 2));

    const response = await fetch('https://llama.celsosilvajunior90.workers.dev', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Log the response from the worker
    const responseData = await response.json();
    console.log("Response from worker:", responseData);

    // Access the nested response correctly
    return responseData[0]?.response?.response || 'No response';
  };

  // Handle prompt submission
  const handleSubmitPrompt = async (event) => {
    event.preventDefault();
    if (!prompt) return;

    setLoading(true);
    try {
      const result = await sendToWorker({ prompt });
      setResponse(result); // Set the result, which should be the string content
    } catch (error) {
      setResponse('Error sending prompt to worker: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle chat submission
  const handleSubmitChat = async (event) => {
    event.preventDefault();
    if (!chatMessage) return;

    setLoading(true);
    try {
      const result = await sendToWorker({ messages: [{ role: 'user', content: chatMessage }] });
      setResponse(result); // Set the result, which should be the string content
    } catch (error) {
      setResponse('Error sending chat to worker: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>LLama AI Assistant</h1>

      {/* Prompt input */}
      <div>
        <h2>Enter a Prompt</h2>
        <textarea
          value={prompt}
          onChange={handlePromptChange}
          placeholder="Type your prompt here"
          rows="4"
          cols="50"
        />
        <button
          onClick={handleSubmitPrompt}
          disabled={loading || !prompt}
        >
          Submit Prompt
        </button>
      </div>

      {/* Chat input */}
      <div>
        <h2>Enter a Chat Message</h2>
        <textarea
          value={chatMessage}
          onChange={handleChatMessageChange}
          placeholder="Type your chat message here"
          rows="4"
          cols="50"
        />
        <button
          onClick={handleSubmitChat}
          disabled={loading || !chatMessage}
        >
          Submit Chat
        </button>
      </div>

      {/* Display response */}
      {loading && <p className="loading">Loading...</p>}
      {response && <p>{response}</p>} {/* Make sure this is a string */}
    </div>
  );
};

export default App;
